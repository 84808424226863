import React, {useContext, useRef, useState, useEffect} from 'react';
import { TextInput, NumberInput, Select, Button, MultiSelect, Modal, Loading, FileUploaderDropContainer, Checkbox, RadioButtonGroup, RadioButton, FormGroup, SelectItem, DatePicker, DatePickerInput, TextArea, Tooltip, InlineLoading } from 'carbon-components-react';
import { RelationshipContext, Relationship, FieldContext, Field, SubmitButtonComponent, Entity, EntityContext, UserContext, fetchAuthenticatedContent } from '@parallelpublicworks/entitysync';
import removeTags from '../util/removeTags';
import trimSummary from '../util/trimSummary';
import isEmptyObject from '../util/isEmptyObject';
import getPostedLabel from '../util/getPostedLabel';
import { v4 as uuidv4 } from 'uuid';
import { InlineNotification } from 'gatsby-theme-carbon';

export function InfoText({text}){
  return text ? (
    <Tooltip
      direction="top"
      tabIndex={0}
      triggerText=""
      
    >
      <p dangerouslySetInnerHTML={{__html: text}} />
    </Tooltip>
  ) : (
    <></>
  )

}

function FileFieldComponent(props){
  const context = RelationshipContext
  const [val, setVal] = useContext(context)
  const [userState, dispatch] = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [currentFile, setCurrentFile] = useState(null)
  const [successNotice, setSuccessNotice] = useState(false)
  const [fileWasSelected, setFileWasSelected] = useState(false)

  useEffect(() => {
    if(props.onChange) props.onChange(val)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSuccessNotice(currentFile !== null && props.autosaving)
  }, [currentFile])


  function onChangeAttachment(e) {
    const file = e.target.files[0]
    const filename = props.filename ? props.filename + `.${file.name.split('.').pop()}` : file.name
    if(props.standalone){
      setFileWasSelected(true)
    }
    if (window !== `undefined`) {
      setVal({ file: file, type: 'file--file', attributes: { filename: filename } })
      setCurrentFile(filename)
      if(props.onChange) props.onChange(e)
    }
  }
   
  function removeAttachment(e) {
    e.preventDefault()
    let typeBundle = val.type.split('--')
    let body = { data: { relationships: {}, type: "file--file", id: val.id } }
    body.data.relationships[props.field] = {}
    setLoading(true)
    fetchAuthenticatedContent(userState.auth, dispatch, `${typeBundle[0]}/${typeBundle[1]}/${val.id}`, "PATCH", body)
      .then((resp) => {
        if (resp) {
          dispatch({
            type: 'CHANGE_ENTITY',
            data: resp.data,
            save: 'nocache'
         });
       }
       setVal(null)
       setCurrentFile(null)
       setLoading(false)
       if(props.onChange) props.onChange(null)
      })
  }
  
  // check for val, if val display upload item and option to 'remove' it
  useEffect(() => {
    if (val?.relationships[props.field]?.data?.attributes?.filename) {
      setCurrentFile(val.relationships[props.field].data.attributes.filename)
      if(props.onChange && currentFile === null) props.onChange(val.relationships[props.field].data.attributes.filename)
    } else if (val?.relationships[props.field]?.data?.id && currentFile === null && !loading) {
      setLoading(true)
      fetchAuthenticatedContent(userState.auth, dispatch, `file/file/${val.relationships[props.field].data.id}`)
        .then(resp => {
          if(resp && resp.data){
            setCurrentFile(resp.data.attributes.filename)
            if(props.onChange) props.onChange(resp.data.attributes.filename)
          }else{
            setCurrentFile(false)
          }
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val])


  let action = currentFile ? (
    <div className="bx--file-container">
      {successNotice && <InlineNotification kind="success">File uploaded</InlineNotification>}
      <span className="bx--file__selected-file">
        <p className="bx--file-filename">{currentFile}</p>
        <span className="bx--file__state-container">
          {!props.standalone && <button onClick={removeAttachment} disabled={props.disabled || loading} aria-label="Clear file" className="bx--file-close" type="button" tabIndex="0">
            <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="16" height="16" viewBox="0 0 32 32" aria-hidden="true">
              <path d="M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z"></path>
            </svg>
          </button>}
        </span>
      </span>
      
    </div>
  ) : (
      <FileUploaderDropContainer
          accept={[
            '.pdf',
            '.doc',
            '.docx',
          ]}
          multiple={false}
          size="default"
          labelText="Click to upload your file"
          tabIndex={0}
          name={props.questionId}
          disabled={props.disabled || loading}
          onChange={onChangeAttachment}
      />
  )
  return <>{action} {props.standalone && currentFile && fileWasSelected && !successNotice && <SubmitButton disabled={props.disabled || loading} onSubmit={(e) => {
    console.log('on submit happened');
    setLoading(true);
    setTimeout(() => {
      setSuccessNotice(true)
      setLoading(false);
    }, 6000);
    // e.preventDefault();

  }}>{loading ? <InlineLoading /> : "Submit letter"}</SubmitButton>}</>

}

export function FileRelationship(props) {
  return (
    <Relationship field={props.field}>
      <FileFieldComponent {...props} isRelationship={true} questionId={props.id} />
    </Relationship>
  )
}


function TextFieldComponent(props){
  const context = props.isRelationship ? RelationshipContext : FieldContext;
  const [val, setVal] = useContext(context);
  const [exceed, setExceed] = useState(null);

  useEffect(() => {
    let value = cleanValue()
    props.onChange(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val])

  const cleanValue = () => {
    let clean = props.isUrl && val && !isEmptyObject(val) && val.uri ? val.uri : val && !isEmptyObject(val) ? val : "";
    if(props.type === 'number'){
      if(clean.length === 0){
        return 0;
      }else{
        return props.number_type === 'float' ? parseFloat(clean) : parseInt(clean);
      }
    }else if (props.type === 'textarea' && clean.value) {
      return clean.value
    }else{
      return clean;
    }
  }

  const onChange = (e) => {
    props.onChange(e);
    let new_val = typeof e.imaginaryTarget !== 'undefined' ? e.imaginaryTarget.value : e.target.value;
    if(props.type === 'number'){
      new_val = props.number_type === 'float' ? parseFloat(new_val).toFixed(2) : parseInt(new_val);
      if(isNaN(new_val)) new_val = 0
    } else if (props.type === 'textarea' && 
      props.id === 'field_perstate'){
      setExceed(null)
      new_val.trim(" ").split(/\s+/).filter(Boolean).length <= props.minimum 
      ?
      setVal(new_val)
      : setExceed("Maximum 500 words reached") 
      e.preventDefault()
      } else {
        setVal(new_val)
      }
  };

  let _props = {...props}
  delete _props.isRelationship
  delete _props.isUrl
  const input_val = cleanValue();
  if(props.type === 'number' && props.number_type === 'percentage' ){
    _props.helperText = '%';
  }


  if(props.type === 'textarea') {
    return (
      <TextArea
        cols={50}
        id={props.id}
        {..._props}
        rows={4}
        value={input_val}
        onChange={onChange}
        invalid={exceed}
        invalidText={exceed}
      />
    )
  }else if(props.type === 'number') {
    let step = props.number_type === 'float' ? 0.1 : 1;
    delete _props.labelText;
    return (
      <NumberInput {..._props} label={props.labelText} step={step} min={0} onChange={onChange} value={input_val} id={props.id} />
    )
  } else{
    return (
      <TextInput {..._props} onChange={onChange} value={input_val} id={props.id} />
    );
  }
}
export function TextField({field, ...props}){
  return (
    <Field field={field}>
      <TextFieldComponent {...props} isRelationship={false} id={field} />
    </Field>
  );
}


export function TextSubField({subField, ...props}){
  const context = props.isRelationship ? RelationshipContext : FieldContext;
  const [val, setVal] = useContext(context);
  const onChange = props.onChange ? (e) => {
    props.onChange(e);
    setVal({...val, [subField]: e.target.value})
  } : (e) => setVal({...val, [subField]: e.target.value});
  let _props = {...props}
  delete _props.isRelationship
  return (
    <TextInput {..._props} id={subField}  onChange={onChange} value={  val && val[subField] ? val[subField] : ""}/>
  );
}



function SelectComponent({isRelationship, relType, field, question, ...props}) {
  const context = isRelationship ? RelationshipContext : FieldContext;
  const makeFieldValue = (val) => {
    let fieldVal;
    if(isRelationship){
      fieldVal = {
        id: val
      };
      if(relType) fieldVal.type = relType;
    }
    else {
      fieldVal = val;
    }
    return fieldVal
  };
  const formattedValue = (values) => {
    return values.map((a_val) => ({ id: a_val, type: relType }))
  }
  const getFieldValue = (fieldValueObj) => {
    if(isRelationship &&
      fieldValueObj.relationships &&
      fieldValueObj.relationships[field] &&
      fieldValueObj.relationships[field].data &&
      fieldValueObj.relationships[field].data[0] &&
      fieldValueObj.relationships[field].data[0].id){
      return fieldValueObj.relationships[field].data[0].id;
    }
    else if(!isRelationship &&
      fieldValueObj.attributes &&
      fieldValueObj.attributes[field]){
      return fieldValueObj.attributes[field];
    }
    return null;
  };
  const [fieldValueObj, setFieldValue] = useContext(context);
  const fieldValue = getFieldValue(fieldValueObj);
  if(fieldValue){
    props.value = fieldValue;
  }
  const onChange = typeof props.onChange !== 'undefined' ? (e) => {
    if(!isRelationship) {
      setFieldValue( makeFieldValue(e.target.value), );
      props.onChange(e);
    } else {
      let result = formattedValue([e.target.value]);
      setFieldValue(result);
      props.onChange([result]);
    }
  } : (e) => { setFieldValue(makeFieldValue(e.target.value)); };
  let _props = {...props};
  delete _props.isRelationship;
  delete _props.charLimit;
  if(_props.invalid) _props.invalid = true;
  return (
    <Select {..._props} id={field} defaultValue={fieldValue} onChange={onChange}>
      {props.children}
    </Select>
  );

}

export const SelectField = ({ isRelationship = false, question, ...props}) => {

  if(isRelationship) {
    return (
      <Relationship field={props.field}>
        <SelectComponent
          {...props}
          isRelationship={isRelationship}
          labelText={question.title}
          question={question}
        >
          <SelectItem 
            text="None"
          />
          {question.relationships.field_options.map((opt) => (
            <SelectItem 
              text={opt.title}
              key={opt.drupal_id}
              id={opt.drupal_id}
              value={opt.drupal_id}
            />
          ))}
        </SelectComponent>
      </Relationship>
    )
  }

  return (
    <Field field={props.field}>
      <SelectComponent {...props} isRelationship={isRelationship} />
    </Field>
  )
};

export const SelectRelationship = (props) => {
  return (
    <Relationship field={props.field}>
      <SelectComponent {...props} isRelationship={true} />
    </Relationship>
  )
};


export function CurrentUserEntity({children, ...props}){
  const [userState, ] = useContext(UserContext);
  return (
    <>
    {
      userState.currentUserId ? (
        <Entity {...props} source={{id: userState.currentUserId }}>
          {children}
        </Entity>
      ) : (
        <Loading/>
      )
    }
    </>
  );

}
function MultiSelectComponent({field, isRelationship, relType, ...props}){
  const context = isRelationship ? RelationshipContext : FieldContext;
	const [fieldValueObj, setFieldValue] = useContext(context);
  const fieldValue = fieldValueObj.value ? fieldValueObj.value : fieldValueObj;
  const mapSelectedToFieldValue = (v) => {
    let fieldVal = v.id;
    if(isRelationship){
      fieldVal = { id: v.id };
      if(relType){
        fieldVal.type = relType
      }
    }
    return fieldVal;
  };

  const onChange = (e) => {
    if(typeof props.onChange !== 'undefined'){
      props.onChange(e);
    }
    let mappedVal = e.selectedItems.map(mapSelectedToFieldValue);
    if(mappedVal.length > 0){
      setFieldValue(mappedVal);
    }
  }

  let _props = {...props};
  delete _props.isRelationship;
  delete _props.charLimit;
  if(_props.invalid) _props.invalid = true;
  if(props.isNew){
    return (
      <MultiSelect {...props} onChange={onChange} id={field}  >
        {props.children}
      </MultiSelect>
    );
  }
  const value = () => {
    const ret = fieldValue.relationships[field];
    const ids_arr = ret && ret.data ? ret.data.map((itm) => itm.id ) : [];
    const val = ids_arr.length ? props.items.filter((itm) => ids_arr.includes(itm.id)) : []
    return val
  }
  let sel_values = value();
  if(!sel_values.length) return <></>;
  return (
    <MultiSelect {...props}  id={field} initialSelectedItems={sel_values} onChange={onChange}>
      {props.children}
    </MultiSelect>
  );
}

// export const MultiSelectField = ({field, ...props}) => {
//   return (
//     <Field field={field}>
//       <MultiSelectComponent {...props} isRelationship={false} />
//     </Field>
//   )
// };

export const MultiSelectRelationship = (props) => {
  return (
    <Relationship field={props.field}>
      <MultiSelectComponent {...props} isRelationship={true} />
    </Relationship>
  )
};



const DisplayIfEqualsComponent = ({children, isRelationship, field, terms, like}) => {
  // const fieldContext = useContext(FieldContext);
  const entContext = useContext(EntityContext)
  if(!isRelationship || !entContext.data.relationships || !entContext.data.relationships[field] || !entContext.data.relationships[field].data){
    return (
      <></>
    );
  }
  let match = terms.find((term) => {
    let found =  term.name.toLowerCase().includes(like.toLowerCase()) && entContext.data.relationships[field] && entContext.data.relationships[field].data.id === term.id
    return found
  })
  if(!match){
    return (
      <></>
    );
  }
  return (
    <>{children}</>
  );
};

export const DisplayIfEqualsField = ({children, ...props}) => {
  return (
    <Field {...props}>
      <DisplayIfEqualsComponent {...props}>
        {children}
      </DisplayIfEqualsComponent>
    </Field>
  );
};


const DisplayIfExistsComponent = ({children, isRelationship, field}) => {
  // const fieldContext = useContext(FieldContext);
  const entContext = useContext(EntityContext)
  
  if(!isRelationship || !entContext.data.relationships || !entContext.data.relationships[field] || !entContext.data.relationships[field].data){
    return (
      <></>
    );
  }
  if(entContext.data.relationships[field].data){
    return (
      <>{children}</>
    );
  }
};

export const DisplayIfExistsField = ({children, ...props}) => {
  return (
    <Field {...props}>
      <DisplayIfExistsComponent {...props}>
        {children}
      </DisplayIfExistsComponent>
    </Field>
  );
}


function DaysAgoComponent(){
  const [fieldObj] = useContext(FieldContext);
  const fieldVal = fieldObj && fieldObj.value ? fieldObj.value : fieldObj;
  let daysAgoString = fieldVal ? getPostedLabel(fieldVal) : null;
  return (
    <>
      {daysAgoString}
    </>
  );
}
export const DaysAgoFieldValue = (props) => {
  return (
    <Field field={props.field}>
      <DaysAgoComponent/>
    </Field>
  );

};

const FileValueComponent = ({field}) => {
  const [value] = useContext(RelationshipContext)
  const there_is_file = value?.relationships && value.relationships[field] && value.relationships[field].data !== null
  return there_is_file ? ( 
    <span>Yes</span>
  ) : (
    <span>No</span>
  )
}
export const FileValue = ({field}) => {
  return (
    <Relationship field={field}>
      <FileValueComponent field={field} />
    </Relationship>
  )
}

const FieldValueComponent = () => {
  const [value] = useContext(FieldContext);
  const fieldVal = value && value.value ? value.value : value
  let processedVal = null;
  if(typeof fieldVal === `string`){
    processedVal = fieldVal.split('\n').map((text, i) => {
      const lineBreak = i !== 0 ? <br/> : <></>;
      return (
        <span key={i}>{lineBreak}{text}</span>
      );
    });
  }else if(isEmptyObject(fieldVal)){
    processedVal = null;
  }else{
    processedVal = fieldVal;
  }
  return (
    <>
      {processedVal}
    </>
  )
};

export const FieldValue = ({field}) => {

  const fieldContext = useContext(FieldContext);
  if(Array.isArray(fieldContext) && fieldContext[0].value){
    return (
      <FieldValueComponent/>
    );
  }
  return (
    <Field field={field}>
      <FieldValueComponent/>
    </Field>
  )
};

const HTMLTextAreaComponent = () =>{
  const [{value: fieldVal}] = useContext(FieldContext);
  return (
    <div dangerouslySetInnerHTML={{__html: fieldVal}}/>
  );
};

export const HTMLTextArea = ({field}) => {
  const fieldContext = useContext(FieldContext);
  if(Array.isArray(fieldContext) && fieldContext[0].value){
    return (
      <HTMLTextAreaComponent/>
    );
  }
  return (
    <Field field={field}>
      <HTMLTextAreaComponent/>
    </Field>
  );
};

const ExcerptComponent = ({title}) =>{
  let context = useContext(FieldContext);
  let fieldVal = context[0] ? context[0].value : null;
  return fieldVal ? <><div className="excerpt"><strong>{title}:</strong>{' '}{trimSummary(removeTags(fieldVal), 250)}</div></> : <></>;
};


export const HTMLExcerpt = ({field, title}) => {
  const fieldContext = useContext(FieldContext);
  if(Array.isArray(fieldContext) && fieldContext[0].value){
    return (
      <ExcerptComponent title={title} />
    );
  }
  return (
    <Field field={field}>
      <ExcerptComponent title={title} />
    </Field>
  );
};



export function SubFieldValue({subField}){
  const [val] = useContext(FieldContext);
  const subVal = val && val[subField] ? val[subField] : null;
  return (
    <>{subVal}</>
  );
}

export const SubmitButton = SubmitButtonComponent((props) => {
  return (
    <Button {...props}>{props.children}</Button>
  );
});


const ModalComponent = SubmitButtonComponent(({onClick, ...props}) => {
  const { cancel } = useContext(EntityContext);

  const onRequestClose = () => {
    if(props.onRequestClose){
      props.onRequestClose();
    }
    cancel();
  };

  const onRequestSubmit = (e) => {
    if(props.onRequestSubmit){
      props.onRequestSubmit(e);
    }
    onClick(e);
  };
  return (
    <Modal key="modal" {...props} onRequestSubmit={onRequestSubmit} onRequestClose={onRequestClose} primaryButtonDisabled={props.disabled} preventCloseOnClickOutside={true}>
      {props.children}
    </Modal>
  );
});

export function ModalEntity({source, type, ...modalProps}){
  return source ? (
    <Entity key="entity" source={source} type={type} componentId="entity" >
      <ModalComponent key="modal-component" {...modalProps}/>
    </Entity>
  ) : (
    <Entity key="entity" type={type} componentId="entity" >
      <ModalComponent key="modal-component" {...modalProps}/>
    </Entity>
  );
}

export function ModalRelationship({type, field, ...modalProps}){
  return (
    <Relationship key="entity" field={field} type={type} componentId="entity" >
      <ModalComponent key="modal-component" {...modalProps} isRelationship={true} />
    </Relationship>
  );
}

function TextAreaComponent({labelText, placeholder, charLimit, onChange, invalid, id, disabled}) {
  const [val, setVal] = useContext(FieldContext);
  const [limitText, setLimitText] = useState('');
  const [exceededMax, setExceededMax] = useState(false)

  useEffect(() => {
    let len = val ? val.length : 0;
    let text = `${len}/${charLimit}`
    setLimitText(text)
    setExceededMax(len > charLimit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val])

  const handleChange = (e) => {
    onChange(e);
    let new_val = e.target.value;
    setVal(new_val)
  }

  return (
    <TextArea 
      id={id}
      value={val ? val.value : ''}
      onChange={handleChange}
      placeholder={placeholder}
      disabled={disabled}
      labelText={`Personal message (${limitText})`}
      invalid={invalid || exceededMax}
    />
  )

}



export function TextAreaField({field, labelText, placeholder, charLimit, onChange, invalid, disabled}){
  return (
    <Field field={field}>
      <TextAreaComponent labelText={labelText} placeholder={placeholder} charLimit={charLimit} onChange={onChange} invalid={invalid} id={field} disabled={disabled} />
    </Field>
  );
}



function CheckboxComponent({field, question, isRelationship, change, invalid, invalidText, relType, disabled, required}){
  const context = isRelationship ? RelationshipContext : FieldContext;
  const [fieldValueObj, setFieldValue] = useContext(context);
  
  useEffect(() => {
    let value = cleanValue()
    let plain = plainValue(value);
    let formatted = formattedValue(plain);
    change(formatted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValueObj?.id])

  const cleanValue = () => {
    return fieldValueObj.value ? fieldValueObj.value : fieldValueObj;
  }

  const plainValue = (clean_value) => { 
    let set
    if (Array.isArray(clean_value?.relationships[field]?.data)) {
      set = new Set(clean_value?.relationships[field]?.data ? clean_value?.relationships[field]?.data?.map((it) => it.id) : []);
    } else {
      set = new Set([clean_value?.relationships[field]?.data?.id]);
    }
    let result = Array.from(set);
    if(Array.isArray(result) && result.length > 0){
      return result
    }else{
      return []
    }
  }

  const formattedValue = (plain) => {
    return plain.map((a_val) => ({ id: a_val, type: relType }))
  }

  const onChange = (checked, val) => {
    if(question.field_is_multiple){
      let checkedSet
      if (Array.isArray(fieldValue?.relationships[field]?.data)) {
        checkedSet = new Set(fieldValue?.relationships[field]?.data ? fieldValue?.relationships[field]?.data?.map((it) => it.id) : []);
      } else {
        checkedSet = new Set([fieldValue?.relationships[field]?.data?.id]);        
      }
      if(checked) {
        checkedSet.add(val);
      }else{
        checkedSet.delete(val);
      }
      let result = Array.from(checkedSet);
      result = formattedValue(result);
      setFieldValue(result);
      change(result)
    }else{
      let result = formattedValue([val]);
      setFieldValue(result)
      change(result)
    }
  }
  
  const fieldValue = cleanValue();
  const fieldPlainValue = plainValue(fieldValue);

  const render = question.field_is_multiple ?
    <>
      {invalidText && <div className="bx--form-requirement" id={`${question.drupal_id}-error-msg`}>{invalidText}</div>}
      {question.relationships.field_options.map((opt) => (
        <Checkbox 
          labelText={<span>{opt.title} <InfoText text={opt.field_info_text} /></span>}
          checked={fieldPlainValue.includes(opt.drupal_id)}
          key={opt.drupal_id}
          id={opt.drupal_id}
          value={opt.drupal_id}
          disabled={disabled}
          onChange={(e, val) => {
            onChange(e, val);
          }}
        />
      ))}
    </> 
  : <>
      {invalidText && <div className="bx--form-requirement" id={`${question.drupal_id}-error-msg`}>{invalidText}</div>}
      <RadioButtonGroup
        name={question.drupal_id}
        valueSelected={typeof fieldPlainValue[0] !== 'undefined' ? fieldPlainValue[0] : null}
        onChange={(e) => {
          onChange(null, e);
        }}
        disabled={disabled}
      >
        {question.relationships.field_options.map((opt) => (
          <RadioButton
            labelText={<span>{opt.title} <InfoText text={opt.field_info_text} /></span>}
            key={uuidv4()}
            id={uuidv4()}
            value={opt.drupal_id}
            disabled={disabled}
          />
        ))}
      </RadioButtonGroup>
    </>;

  return (
    <div className={`checkbox-field ${invalid ? 'invalid' : ''}`}>
      <FormGroup
        legendText={`${question.title} ${required ? '' : '(optional)'}`}
      >
        {render}
      </FormGroup>
    </div>
  );

}

export const CheckboxField = ({field, question, onChange, invalid, invalidText, relType, disabled, required}) => {
  return (
    <Relationship field={field}>
      <CheckboxComponent field={field} required={required} question={question} change={onChange} invalid={invalid} invalidText={invalidText} isRelationship={true} relType={relType} disabled={disabled} />
    </Relationship>
  )
};


function AddressSubField({subField, labelText, placeholder, change, disabled}) {
  const [val, setVal] = useContext(FieldContext);
  
  useEffect(() => {
    change({target: {value: val}}, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const onChange = (e) => {
    change(e, false);
    setVal(e.target.value);
  }
  if(subField.includes('country')){
    return (
      <Select
        light
        id={subField} 
        labelText={labelText}
        onChange={onChange} 
        className='country-select'
        value={val ? val : ""}
        defaultValue={"US"}
        disabled={disabled}
      >
        <SelectItem value="" text="None" />
        <SelectItem value="US" text="United States" />
      </Select>
    )
  }
  if(subField.includes('state')){
    return (
      <Select
        id={subField} 
        labelText={labelText}
        onChange={onChange} 
        value={val ? val : ""}
        light
        disabled={disabled}
      >
        <SelectItem
          text="Choose a state..."
          value=""
        />
        <SelectItem value="AL" text="Alabama" />
        <SelectItem value="AK" text="Alaska" />
        <SelectItem value="AS" text="American Samoa" />
        <SelectItem value="AZ" text="Arizona" />
        <SelectItem value="AR" text="Arkansas" />
        <SelectItem value="AA" text="Armed Forces (AA)" />
        <SelectItem value="AE" text="Armed Forces (AE)" />
        <SelectItem value="AP" text="Armed Forces (AP)" />
        <SelectItem value="CA" text="California" />
        <SelectItem value="CO" text="Colorado" />
        <SelectItem value="CT" text="Connecticut" />
        <SelectItem value="DE" text="Delaware" />
        <SelectItem value="DC" text="District of Columbia"/>
        <SelectItem value="FL" text="Florida" />
        <SelectItem value="GA" text="Georgia" />
        <SelectItem value="GU" text="Guam" />
        <SelectItem value="HI" text="Hawaii" />
        <SelectItem value="ID" text="Idaho" />
        <SelectItem value="IL" text="Illinois" />
        <SelectItem value="IN" text="Indiana" />
        <SelectItem value="IA" text="Iowa" />
        <SelectItem value="KS" text="Kansas" />
        <SelectItem value="KY" text="Kentucky" />
        <SelectItem value="LA" text="Louisiana" />
        <SelectItem value="ME" text="Maine" />
        <SelectItem value="MH" text="Marshall Islands" />
        <SelectItem value="MD" text="Maryland" />
        <SelectItem value="MA" text="Massachusetts" />
        <SelectItem value="MI" text="Michigan" />
        <SelectItem value="FM" text="Micronesia" />
        <SelectItem value="MN" text="Minnesota" />
        <SelectItem value="MS" text="Mississippi" />
        <SelectItem value="MO" text="Missouri" />
        <SelectItem value="MT" text="Montana" />
        <SelectItem value="NE" text="Nebraska" />
        <SelectItem value="NV" text="Nevada" />
        <SelectItem value="NH" text="New Hampshire" />
        <SelectItem value="NJ" text="New Jersey" />
        <SelectItem value="NM" text="New Mexico" />
        <SelectItem value="NY" text="New York" />
        <SelectItem value="NC" text="North Carolina" />
        <SelectItem value="ND" text="North Dakota" />
        <SelectItem value="MP" text="Northern Marian Islands" />
        <SelectItem value="OH" text="Ohio" />
        <SelectItem value="OK" text="Oklahoma" />
        <SelectItem value="OR" text="Oregon" />
        <SelectItem value="PW" text="Palau" />
        <SelectItem value="PA" text="Pennsylvania" />
        <SelectItem value="PR" text="Puerto Rico" />
        <SelectItem value="RI" text="Rhode Island" />
        <SelectItem value="SC" text="South Carolina" />
        <SelectItem value="SD" text="South Dakota" />
        <SelectItem value="TN" text="Tennessee" />
        <SelectItem value="TX" text="Texas" />
        <SelectItem value="UT" text="Utah" />
        <SelectItem value="VT" text="Vermont" />
        <SelectItem value="VI" text="Virgin Islands" />
        <SelectItem value="VA" text="Virginia" />
        <SelectItem value="WA" text="Washington" />
        <SelectItem value="WV" text="West Virginia" />
        <SelectItem value="WI" text="Wisconsin" />
        <SelectItem value="WY" text="Wyoming" />
      </Select>
    )
  }
  return (
    <TextInput disabled={disabled} light id={subField} labelText={labelText} placeholder={placeholder} onChange={onChange} value={val ? val : ""} />
  );
}

const getDefaultAddressFields = (field_exclude_fields) => {
  let defaults = {
    field_country: 'US',
    field_address_line1: '',
    field_address_line2: '',
    field_city: '',
    field_state: '',
    field_zip_code: ''
  };
  if(field_exclude_fields && field_exclude_fields.length > 0){
    for (const exclude_key of field_exclude_fields) {
      delete defaults[exclude_key]
    }
  }
  return defaults;
}

function AddressComponent({field, question, change, invalid, invalidText, disabled, labelText, required}) {
  
  const { data } = useContext(EntityContext);
  let countrySelected = data?.attributes && data.attributes[`field_country_${question.field_identifier}`];
  
  const currentAddressRef = useRef(getDefaultAddressFields());
  const validatedFields = useRef([])
  let excludes = question.field_exclude_fields && question.field_exclude_fields.length > 0 ? question.field_exclude_fields : [];


  const onChange = (e, address_key, initial = false) => {
    let new_address = currentAddressRef.current
    new_address[address_key] = e.target.value
    currentAddressRef.current = new_address
    validatedFields.current = [...validatedFields.current, address_key]

    let allFields = ['field_address_line1', 'field_city', 'field_state', 'field_zip_code']
    allFields = allFields.filter(item => !excludes.includes(item))

    let hasAllFields = allFields.every(i=> validatedFields.current.includes(i))

    if(initial === false || (initial === true && hasAllFields === true))
      change(new_address)
    
  }
  
  return (
    <FormGroup className={`address-field ${invalid && 'invalid'}`} legendText={labelText}>
      {invalidText && invalidText.length > 0 && <div className="bx--form-requirement" id={`${question.drupal_id}-error-msg`}>{invalidText}</div>}
      {!excludes.includes('field_address_line1') && <Field field={`field_address_line1_${field}`}><AddressSubField subField={`field_address_line1_${field}`} labelText={`Address line 1 ${required ? '' : '(optional)'}`} placeholder="Street..." change={(e, initial)=>onChange(e, "field_address_line1", initial )} disabled={disabled} /></Field>}
      {!excludes.includes('field_address_line2') && <Field field={`field_address_line2_${field}`}><AddressSubField subField={`field_address_line2_${field}`} labelText="Address line 2" placeholder="Suite, Apt..." change={(e, initial)=>onChange(e, "field_address_line2", initial )} disabled={disabled} /></Field>}
      <div className="address--row">
      {!excludes.includes('field_city') && <Field field={`field_city_${field}`}><div className="address--col-5"><AddressSubField subField={`field_city_${field}`} labelText={`City ${required ? '' : '(optional)'}`} change={(e, initial)=>onChange(e, "field_city", initial )} disabled={disabled} /></div></Field>}
      {(!excludes.includes('field_state') && (!countrySelected || countrySelected == "US")) && <Field field={`field_state_${field}`}><div className="address--col-5"><AddressSubField subField={`field_state_${field}`} labelText={`State ${required ? '' : '(optional)'}`} change={(e, initial)=>onChange(e, "field_state", initial )} disabled={disabled} /></div></Field>}
      {!excludes.includes('field_zip_code') && <Field field={`field_zip_code_${field}`}><div className="address--col-2"><AddressSubField subField={`field_zip_code_${field}`} labelText={`Postal Code ${required ? '' : '(optional)'}`} change={(e, initial)=>onChange(e, "field_zip_code", initial )} disabled={disabled} /></div></Field>}
      {!excludes.includes('field_country') && <Field field={`field_country_${field}`}><div className="address--col-5"><AddressSubField subField={`field_country_${field}`} labelText={`Country ${required ? '' : '(optional)'}`} placeholder="Country..." change={(e, initial)=>onChange(e, "field_country", initial )} disabled={disabled} /></div></Field>}
      </div>
    </FormGroup>
  )
}

export const AddressField = ({field, question, onChange, invalid, invalidText, disabled, required, labelText}) => {
  return (
      <AddressComponent field={field} question={question} change={onChange} invalid={invalid} invalidText={invalidText} disabled={disabled} required={required} labelText={labelText} />
  );
}

function DateComponent({field, question, change, invalid, invalidText, labelText}) {
  const [val, setVal] = useContext(FieldContext);
  
  
  useEffect(() => {
    let value = cleanValue()
    change(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cleanValue = () => {
    return val && !isEmptyObject(val) ? val : "";
  }

  const onChange = (e) => {
    change(e);
    setVal(e)
    // setVal(e.target.value)
  }
  
  let input_val = cleanValue();
  
  return (
    <DatePicker dateFormat="YYYY-MM-DD" datePickerType="single" onChange={onChange} invalid={invalid} invalidText={invalidText}>
      <DatePickerInput
        id={field}
        placeholder="YYYY-MM-DD"
        labelText={labelText}
        type="text"
        format="YYYY-MM-DD"
        value={input_val}
      />
    </DatePicker>
  );
}

export const DateField = ({field, question, onChange, invalid, invalidText, labelText}) => {
  return (
    <Field field={field}>
      <DateComponent field={field} question={question} change={onChange} invalid={invalid} invalidText={invalidText} labelText={labelText} />
    </Field>
  );
}


